<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="选择用户" prop="admin_id" :rules="rules.required">
					<el-select v-model="form.admin_id" placeholder="请选择" :disabled="isEdit">
						<el-option v-for="item in userOptions" :key="item.id" :label="item.nickname+'-'+item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="技师等级" prop="level" :rules="rules.required">
					<el-select v-model="form.level" placeholder="请选择">
						<el-option v-for="item in levelOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="技师描述" prop="des">
					<el-input v-model="form.des" type="textarea" :autosize="{ minRows: 4}" style="width: 600px;"></el-input>
				</el-form-item>
				<el-form-item label="提成配置">
					<div class="title">品牌服务</div>
					<template v-for="item in brandServeData">
						<table class="pure-table" :key="item.id">
							<thead>
								<tr>
									<th colspan="6">{{item.name}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(serve,index) in item.serve" :key="serve.id">
									<td width="80" align="center">
										<el-checkbox v-model="serve.can">可服务</el-checkbox>
									</td>
									<td width="50" align="center">{{index+1}}</td>
									<td width="200">
										<div class="flex justify-start align-center">
											<el-image style="width: 30px; height: 30px;" :src="serve.cover"></el-image>
											<div class="margin-left-sm flex-sub">{{serve.serve_name}}</div>
										</div>
									</td>
									<td width="150">服务价格：￥{{serve.price}}</td>
									<td width="150">参考提成：￥{{serve.server_commission||'0.00'}}</td>
									<td width="200">技师提成：<el-input-number v-model="serve.ticheng" :precision="2" :min="0" :max="99999" label="技师提成"
										controls-position="right" style="width:130px;"></el-input-number>
									</td>
								</tr>
							</tbody>
						</table>
					</template>
					<div class="title">自营服务服务</div>
					<template v-for="item in selfServeData">
						<table class="pure-table" :key="item.id">
							<thead>
								<tr>
									<th colspan="6">{{item.name}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(serve,index) in item.serve" :key="serve.id">
									<td width="80" align="center">
										<el-checkbox v-model="serve.can">可服务</el-checkbox>
									</td>
									<td width="50" align="center">{{index+1}}</td>
									<td width="200">
										<div class="flex justify-start align-center">
											<el-image style="width: 30px; height: 30px;" :src="serve.cover"></el-image>
											<div class="margin-left-sm flex-sub">{{serve.serve_name}}</div>
										</div>
									</td>
									<td width="150">服务价格：￥{{serve.price}}</td>
									<td width="150">参考提成：￥{{serve.server_commission}}</td>
									<td width="200">技师提成：<el-input-number v-model="serve.ticheng" :precision="2" :min="0" :max="99999" label="技师提成"
										controls-position="right" style="width:130px;"></el-input-number>
									</td>
								</tr>
							</tbody>
						</table>
					</template>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button @click="$router.back()">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				title: this.$route.params.id ? "编辑技师" : "新增技师",
				userOptions: [],
				// brandTreeData: [],
				// selfTreeData: [],
				levelOptions: [{
					label: '初级',
					value: 1
				}, {
					label: '中级',
					value: 2
				}, {
					label: '高级',
					value: 3
				}],
				brandServeData: [],
				selfServeData: [],
				form: {
					id: this.$route.params.id,
					admin_id: undefined,
					level: 3,
					des: undefined,
					serve: {
						brand: {}, // 品牌服务提成
						self: {}, // 自营服务提成
					}
				}
			};
		},
		computed: {
			isEdit() {
				return Boolean(this.form.id)
			}
		},
		mounted() {
			// 获取用户列表
			this.$api.user.getUsers({
				page: 1,
				page_size: 1000
			}).then(res => {
				this.userOptions = res.data.data;
			})
			// // 获取品牌服务分类
			// this.$api.classify.getBrandClassify(this.$api.classify.SERVICE).then(res => {
			// 	this.brandTreeData = res.data
			// })
			// // 获取自营服务分类
			// this.$api.classify.getClassify(this.$api.classify.SERVICE, {
			// 	page: 1,
			// 	page_size: 1000
			// }).then(res => {
			// 	this.selfTreeData = res.data
			// })

			let promiseArr = [this.$api.project.getClassifyBrandServe(), this.$api.project.getClassifySelfServe()];
			// 获取当前编辑的技师信息
			if (this.form.id) {
				promiseArr.push(this.$api.staff.getStaff({
					id: this.form.id
				}))
			}
			Promise.all(promiseArr).then(res => {
				// 如果是编辑，则赋值现有数据
				if (res[2]) {
					this.form = res[2].data
					this.form.role = this.form.role ? this.form.role : []
				}
				// 获取父子结构的品牌分类服务
				res[0].data.forEach(c => {
					c.serve.forEach(item => {
						// 如果是编辑，则从获取的技师详情中取值
						if (res[2]) {
							item.ticheng = res[2].data.serve.brand[item.id] || 0;
							item.can = Boolean(res[2].data.serve.brand[item.id]) || res[2].data.serve.brand[item.id] === 0;
						} else {
							// 否则设置为0
							item.ticheng = 0;
							item.can = true;
						}
					})
				})
				this.brandServeData = res[0].data;
				// 获取父子结构的自营分类服务
				res[1].data.forEach(c => {
					c.serve.forEach(item => {
						item.server_commission = Number(item.server_commission).toFixed(2)
						// 如果是编辑，则从获取的技师详情中取值
						if (res[2]) {
							item.ticheng = res[2].data.serve.self[item.id] || 0;
							item.can = Boolean(res[2].data.serve.self[item.id]) || res[2].data.serve.self[item.id] === 0;
						} else {
							// 否则设置为服务中的参考提成
							item.ticheng = Number(item.server_commission);
							item.can = true;
						}
					})
				})
				this.selfServeData = res[1].data;
			})




		},
		methods: {
			// 品牌服务tree改变
			brandCheckChange() {
				this.form.serve.brand = this.$refs.brandTree.getCheckedKeys()
			},
			// 自营服务tree改变
			selfCheckChange() {
				this.form.serve.self = this.$refs.selfTree.getCheckedKeys()
			},
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						// 构建提成，按照是否可服务来创建
						this.form.serve.brand = {}
						this.brandServeData.forEach(cla => {
							cla.serve.forEach(ser => {
								if (ser.can) {
									this.form.serve.brand[ser.id] = ser.ticheng;
								}
							})
						})
						this.form.serve.self = {}
						this.selfServeData.forEach(cla => {
							cla.serve.forEach(ser => {
								if (ser.can) {
									this.form.serve.self[ser.id] = ser.ticheng;
								}
							})
						})
						const loading = this.$loading();
						this.$api.staff.save(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped>
	.form_box {
		padding-top: 30px;
	}

	/* 表格样式 */
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	td,
	th {
		padding: 0;
	}

	.pure-table {
		border-collapse: collapse;
		border-spacing: 0;
		empty-cells: show;
		border: 1px solid #cbcbcb;
	}

	.pure-table td,
	.pure-table th {
		line-height: 20px;
		border-left: 1px solid #cbcbcb;
		border-width: 0 0 0 1px;
		font-size: inherit;
		margin: 0;
		overflow: visible;
		padding: 10px 10px;
	}

	.pure-table td {
		background-color: transparent;
	}

	.pure-table thead {
		background-color: #e0e0e0;
		color: #000;
		text-align: left;
		vertical-align: bottom;
	}

	.title {
		font-weight: bold;
	}
</style>
